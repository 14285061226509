#root-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#nav-text {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 900;
  font-family: "Handlee", cursive;
}

#toolbar-link-sm {
  color: #02572d;
  padding-top: 0px;
  padding-bottom: 0px;
}

#toolbar-link-sm:hover {
  background-color: #016936;
  color: #ffff;
}

#toolbar-link-open-sm {
  background-color: #016936;
  color: #f7f7f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

#toolbar-link-open-sm:hover {
  flex-shrink: initial;
  background-color: #016936;
  color: #f7f7f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

#toolbar-link {
  flex-shrink: initial;
  color: #016936;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 20%;
}

#toolbar-link-cursos {
  flex-shrink: initial;
  color: #016936;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 20%;
}

#toolbar-link-open-sm-cursos {
  background-color: #016936;
  color: #f7f7f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

#toolbar-link-cursos:hover {
  background-color: #016936;
  color: #ffff;
}

#toolbar-link-open-sm-cursos:hover {
  background-color: #016936;
  color: #ffff;
}

#toolbar-link:hover {
  width: 20%;
  background-color: #016936;
  color: #ffff;
}

#toolbar-link-open {
  width: 20%;
  flex-shrink: initial;
  background-color: #016936;
  color: #f7f7f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

#toolbar-link-open:hover {
  flex-shrink: initial;
  background-color: #016936;
  color: #f7f7f7;
  padding-top: 0px;
  padding-bottom: 0px;
}

#icon-nav {
  margin-right: 5px;
  margin-bottom: 5px;
}

#main-background {
  background-color: #ffffff;
  background-image: url("https://www.transparenttextures.com/patterns/45-degree-fabric-light.png");
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}
